import {
  IGetAsmDashboardLoading,
  IGetAsmPerformance,
  IGetTopBdePerformance,
  IGetTopRetailerPerformance,
} from "types/asmTypes";

export const ASM_DASHBOARD_PERFORMANCE = "ASM_DASHBOARD_PERFORMANCE";
export const ASM_DASHBOARD_PERFORMANCE_V2 = "ASM_DASHBOARD_PERFORMANCE_V2";
export const ASM_TABLEAU_DASHBOARD_PERFORMANCE_V2 =
  "ASM_TABLEAU_DASHBOARD_PERFORMANCE_V2";
export const TOP_RETAILERS_PERFORMANCE = "TOP_RETAILERS_PERFORMANCE";
export const TOP_BDE_PERFORMANCE = "TOP_BDE_PERFORMANCE";
export const TOP_SELLING_SKU = "TOP_SELLING_SKU";
export const BRANDS_IN_FOCUS = "BRANDS_IN_FOCUS";
export const ASM_DASHBOARD_LOADING = "ASM_DASHBOARD_LOADING";
export const ASM_TABLE_LOADING = "ASM_TABLE_LOADING";

export interface IGetAsmPerformanceActionType {
  type: typeof ASM_DASHBOARD_PERFORMANCE;
  data: IGetAsmPerformance;
}

export interface IGetTopBdePerformanceActionType {
  type: typeof TOP_BDE_PERFORMANCE;
  data: IGetTopBdePerformance[];
}

export interface IGetTopSellingSkuActionType {
  type: typeof TOP_SELLING_SKU;
  data: any;
}

export interface IGetBrandsInFocusActionType {
  type: typeof BRANDS_IN_FOCUS;
  data: any;
}

export interface IGetAsmDashboardLoadingActionType {
  type: typeof ASM_DASHBOARD_LOADING;
  data: IGetAsmDashboardLoading;
}

export interface IAsmTableLoadingActionType {
  type: typeof ASM_TABLE_LOADING;
  data: IGetAsmDashboardLoading;
}

export interface ITopRetailersPerformanceActionType {
  type: typeof TOP_RETAILERS_PERFORMANCE;
  data: IGetTopRetailerPerformance;
}

export type AsmDashboardActionType =
  | IGetAsmPerformanceActionType
  | IGetTopBdePerformanceActionType
  | IGetTopSellingSkuActionType
  | IGetBrandsInFocusActionType
  | IGetAsmDashboardLoadingActionType
  | ITopRetailersPerformanceActionType
  | IAsmTableLoadingActionType;

export type ASMActionType = AsmDashboardActionType;
